import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Login } from './login';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private url = environment.apiUrl + '/api/v1/users';

  private logoutDataSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  message$ = this.logoutDataSubject.asObservable();

  constructor(private http: HttpClient) { }

  login(login: Login): Observable<any> {
    const url = `${this.url}/login`;

    let formData: FormData = new FormData();
    formData.append('email', login.email);
    formData.append('pass', login.password);

    return this.http.post(url, formData);
  }

  forgotPass(email: string): Observable<any> {
    const url = `${this.url}/forgotPass`;

    let formData: FormData = new FormData();
    formData.append('email', email);

    return this.http.post(url, formData);
  }

  changePassToken(token: string, password: string): Observable<any> {
    const url = `${this.url}/changePassToken`;

    let formData: FormData = new FormData();
    formData.append('token', token);
    formData.append('pass', password);

    return this.http.post(url, formData);
  }

  logout(message: string) {
    this.logoutDataSubject.next(message);
  }
}
